import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Contacts from "../../../Interfaces/contacts";
import { Deal } from "../../../Interfaces/deal";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getContactsFromId } from "../../../store/Communications/actions";
import { selectAllContacts } from "../../../store/Communications/selectors";
import { getDealFromId } from "../../../store/Deal/actions";
import { selectDeal } from "../../../store/Deal/selector";
import { submitSurvey, SurveyData } from "../../../store/Surveys/actions";
import { resetSurvey } from "../../../store/Surveys/reducer";
import {
  selectSurveysError,
  selectSurveysSuccess,
} from "../../../store/Surveys/selectors";
import "../../../styles/fields.scss";
import { checkRequiredFields, sendToast } from "../../../utils/helpers";
import DefaultButton from "../../Buttons/DefaultButton";
import useRecentlyMatchedDefaultFormBuilder from "../FormHook/defaultFormHook";
import { getFields } from "./config";

const LOMatchedSurvey = () => {
  const dispatch = useAppDispatch();
  const { agents } = (useSelector(state =>
    selectAllContacts(state)
  ) as Contacts) || { agents: [] };
  const loadingData = useAppSelector(
    state => state.deals.loading || state.communications.loading
  );
  const deal = useSelector(state => selectDeal(state)) as Deal;
  const [config, setConfig] = useState(null) as any[];
  const [loading, setLoading] = useState(false);
  const [fields, data] = useRecentlyMatchedDefaultFormBuilder({ config });
  const success = useSelector(state => selectSurveysSuccess(state));
  const error = useSelector(state => selectSurveysError(state));
  useEffect(() => {
    if (success) {
      setLoading(false);
      navigate("/success/", {
        state: {
          type: "RecentlyMatchedLO",
        },
      });
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      sendToast(
        error.error ||
          "There was an error submitting the survey, please try again.",
        { type: "error" }
      );
      dispatch(resetSurvey());
    }
  }, [error]);

  useEffect(() => {
    if (agents && deal) {
      const newFields = getFields(agents, deal);
      setConfig(newFields);
    }
  }, [agents, deal]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const dealId = urlParams.get("deal_id");
      if (!dealId) {
        toast("No Deal Id was attached");
        navigate("/404/", {
          state: {
            error:
              "There was an error with your form. Please contact a Clever representative at 833-7-CLEVER",
          },
        });
      } else {
        dispatch(getDealFromId(dealId));
        dispatch(getContactsFromId(dealId));
      }
    }
  }, []);

  const handleFormSubmit = () => {
    setLoading(true);
    const { invalid, requiredLabelName } = checkRequiredFields(config, data);
    if (!invalid) {
      const urlParams = new URLSearchParams(window.location.search);
      const dealId = urlParams.get("deal_id");
      const dataFields = {
        deal_id: dealId,
        data: {
          ...data,
          survey_name: "LO Matched Survey",
        },
      } as SurveyData;

      dispatch(submitSurvey(dataFields));
    } else {
      setLoading(false);
      sendToast(`Please fill out the following field: ${requiredLabelName}`, {
        type: "error",
      });
    }
  };

  return (
    <div className="defaultFormContainer">
      {deal && (
        <h2>
          Matched Survey - {deal.customer.firstname} {deal.customer.lastname}{" "}
        </h2>
      )}
      <div className="defaultFormBody">
        {deal && deal.customer && (
          <p>
            This survey is broken into two sections. The first will ask about
            your first impression of the agent provided for{" "}
            {deal.customer.firstname}
            &apos;s transaction. The second will ask about your satisfaction
            with Clever&apos;s communication so far.
            <br />
            <br /> For all scale questions, please consider 1 the lowest
            possible rating.
          </p>
        )}
        <div>
          {fields}
          {fields && fields.length === 0 && !loadingData && (
            <p>
              There was an error with the form. Please contact Clever at
              833-7-CLEVER.
            </p>
          )}
          {fields.length > 0 && (
            <DefaultButton
              text={loading ? "Loading" : "Submit"}
              className="defaultButton"
              disabled={loading}
              callback={() => handleFormSubmit()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LOMatchedSurvey;
