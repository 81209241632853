import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import LOMatchedSurvey from "../components/FormBuilder/LOMatchedSurvey/LOMatchedSurvey";
import "../styles/form.scss";

const LOMatched = () => (
  <AuthenticationLayout>
    <LOMatchedSurvey />
  </AuthenticationLayout>
);

export default LOMatched;
