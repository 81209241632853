import Agent from "../../../Interfaces/agent";
import { Deal } from "../../../Interfaces/deal";
import { FieldTypes } from "../../../utils/fieldTypes";

const RecentlyMatchedAgentDefaultFields = (
  agent: Agent,
  index: number,
  deal: Deal
) => {
  const fields = [
    {
      type: FieldTypes.header,
      keyName: `agentHeader${index}`,
      label: `${agent.firstname} ${agent.lastname} ${
        agent.brokerage_name ? `of ${agent.brokerage_name}` : ""
      }?`,
    },
    {
      label: `Has ${agent.firstname} ${agent.lastname} attempted to contact you yet?`,
      keyName: `has_agent_attempted_to_contact_you_yet_`,
      type: FieldTypes.radioText,
      required: true,
      options: ["Yes", "No"],
    },
    {
      label: `What medium did ${agent.firstname} ${agent.lastname} first reach out through?`,
      keyName: `what_medium_did_agent_first_reach_out_through_`,
      type: FieldTypes.radioText,
      options: ["Text Message", "Email", "Phone Call"],
      triggers: {
        has_agent_attempted_to_contact_you_yet_: ["Yes"],
      },
    },
    {
      label: `Based on first impressions, does ${agent.firstname} ${agent.lastname} seem like a good fit for the buyer?`,
      keyName: `does_agent_seem_like_a_good_fit_for_the_buyer_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      label: `How well has  ${agent.firstname} ${agent.lastname} presented themself so far? (Over the phone, written communication, online presence, etc)`,
      keyName: `how_well_has_agent_presented_themself_so_far_`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `How confident are you that the agent understands the partnership between Clever and ${
        deal.loan_officer.company_name || "Yourself"
      }?`,
      keyName: `how_confident_are_you_that_the_agent_understands_the_partnership_between_clever_and_lender_`,
      type: FieldTypes.radioNumber,
      required: true,
      options: ["1", "2", "3", "4", "5"],
    },
  ];

  return fields;
};

const RecentlyMatchedGeneralFields = () => [
  {
    type: FieldTypes.header,
    keyName: "generalHeader",
    label: `Clever Real Estate`,
  },
  {
    label:
      "Based on your experience with this referral so far, how satisfied are you with Clever’s communication and attentiveness?",
    keyName:
      "how_satisfied_are_you_with_clever_s_communication_and_attentiveness_",
    type: FieldTypes.radioNumber,
    required: true,
    options: ["1", "2", "3", "4", "5"],
  },
  {
    label: "Where did we fall short of your expectations?",
    keyName: "where_did_we_fall_short_of_your_expectations___mp_lo_",
    type: FieldTypes.textarea,
    triggers: {
      how_satisfied_are_you_with_clever_s_communication_and_attentiveness_: [
        "1",
        "2",
        "3",
        "4",
      ],
    },
  },
];

const getFields = (agents: Agent[], deal: Deal) => {
  let fields = [] as any[];
  const agentFields = agents
    .slice(0, 2)
    .map((agent, index) =>
      RecentlyMatchedAgentDefaultFields(agent, index, deal)
    )
    .flat();
  const generalFields = RecentlyMatchedGeneralFields();
  fields = [agentFields, generalFields].flat();
  return fields;
};

export { getFields };
